<template>
  <auth-layout>
    <form>
      <card-item :margin="true">
        <ab-input-field
          :label="$t('app.email')"
          v-model="email"
          type="email"
          :error="$v.email.$error"
          :errorMessage="$t('forms.valid_email')"
        ></ab-input-field>
        <ab-input-field
          v-model="password"
          :label="$t('forms.password')"
          type="password"
          :error="$v.password.$error"
          :errorMessage="$t('forms.password_not_correct')"
        ></ab-input-field>
        <div class="login__error" v-if="submitStatus">
          <p>{{ submitStatus }}</p>
        </div>
      </card-item>
      <div class="spacer--20"></div>
      <div class="grid-x align-middle">
        <div class="cell auto">
          <router-link to="/forgot-password">{{
              $t('app.forgot_password')
            }}
          </router-link>
        </div>
        <div class="cell auto text-right">
          <span
            v-if="submitting"
            class="button--primary is-saving"
          ><ab-loader/> {{ $t('app.login') }}
          </span>
          <button v-else type="submit" @click="handleSubmit" class="button--primary">
            {{ $t('app.login') }}
          </button>
        </div>
      </div>
    </form>
  </auth-layout>
</template>

<script>
import AuthLayout from '../../layouts/AuthLayout'
import backend from '@/backend'
import { email, minLength, required } from 'vuelidate/lib/validators'

export default {
  name: 'LoginPage',
  data () {
    return {
      email: '',
      password: '',
      submitStatus: null,
      submitting: false
    }
  },
  methods: {
    handleSubmit (e) {
      e.preventDefault()
      this.submitStatus = null
      this.submitting = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        setTimeout(() => {
          backend
            .login(this.email, this.password)
            .then(() => {
              this.$emit('loggedIn')
              localStorage.setItem('locale', this.$store.state.user.locale)
              this.$i18n.locale = this.$store.state.user.locale
              this.$i18n.ensureTranslations().then(() => {
                if (this.$route.params.nextUrl != null) {
                  this.$router.push(this.$route.params.nextUrl)
                } else {
                  const page = this.$store.state.interface_settings.navigation ? this.$store.state.interface_settings.navigation : '/dashboard'

                  this.$router.push({
                    path: page
                  })
                }
              })
            })
            .catch(() => {
              this.submitting = false
              this.submitStatus = this.$t('forms.login_error')
            })
        }, 500)
      }
    }
  },
  components: {
    'auth-layout': AuthLayout
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required,
      minLength: minLength(1)
    }
  }
}
</script>

<style scoped></style>
